import { useState, useEffect, useRef } from 'react'
import { HeroSection } from './hero/hero-section'
import { ArticlesSection } from './articles/articles-section'
import { EbookSection } from './ebook/ebook-section'
import { FeaturedPlansSection } from './featured-plans/featured-plans-section'
import { ToolsSection } from './tools/tools-section'
import type { FeaturedPlans } from './featured-plans/featured-plans-model'
import { GetStartedSection } from '@modules/get-started/get-started-section/get-started-section'
import { CommonDialogs } from '@components/layout/common-dialogs'
import type { GetStartedDialogHandle } from '@modules/get-started/get-started-dialog'
import type { ProcessedImages } from '@lib/image'
import { AnnouncementSection } from './announcement/announcement-section'
import { getStateFromIp } from '@lib/us-states'
import { MapSection } from './map/map-section'

interface Props {
  featuredPlansData: FeaturedPlans
  processedImages: ProcessedImages
}

export const HomePage = ({ featuredPlansData, processedImages }: Props) => {
  const [userStateId, setUserStateId] = useState<string | undefined>(undefined)
  const getStartedDialogRef = useRef<GetStartedDialogHandle>(null)

  const openGetStarted = (stateId?: string) => {
    getStartedDialogRef.current?.openGetStartedOrSavingsCenter(stateId)
  }

  const openGetStartedIfQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('savings-center') === 'true') {
      openGetStarted()
    }
  }

  useEffect(() => {
    const fetchState = async () => {
      const stateFromIp = await getStateFromIp()
      setUserStateId(() => stateFromIp?.id)
    }

    fetchState()
    openGetStartedIfQuery()
  }, [])

  return (
    <div className="flex flex-col">
      <HeroSection openGetStarted={openGetStarted} userStateId={userStateId} />
      <AnnouncementSection />
      <MapSection userStateId={userStateId} />
      <ArticlesSection processedImages={processedImages} />
      <EbookSection processedImages={processedImages} />
      <FeaturedPlansSection data={featuredPlansData} processedImages={processedImages} />
      <ToolsSection />
      <GetStartedSection openGetStarted={openGetStarted} processedImages={processedImages} />
      <CommonDialogs getStartedDialogRef={getStartedDialogRef} />
    </div>
  )
}
