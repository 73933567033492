import FullWidthContainer from '@components/layout/full-width-container'
import { Carousel, CarouselContent, CarouselItem } from '@components/ui/carousel'
import { LinkButton } from '@components/ui/link-button'
import { ToolCard } from '@modules/tools/tool-card'
import { TOOLS } from '@modules/tools/tools-model'

export const ToolsSection = () => {
  return (
    <section className="bg-blue-50 pt-[70px]">
      <FullWidthContainer>
        <div className="mb-[15px] flex items-center">
          <h2 className="text-title-md small-desktop:text-title-lg-desktop">
            Popular tools and calculators
          </h2>
        </div>
        <p className="mb-[20px] tablet:mb-[40px] max-w-[550px]">
          Explore our range of consumer and professional tools to simplify your college savings
          journey.
        </p>
        <div className="hidden tablet:flex mb-[30px] flex-wrap gap-[20px]">
          {TOOLS.map((tool, index) => (
            <ToolCard key={index} tool={tool} background="white" />
          ))}
        </div>
      </FullWidthContainer>
      <div className="tablet:hidden overflow-x-hidden mb-[20px]">
        <Carousel className="w-full max-w-full">
          <CarouselContent>
            {TOOLS.map((tool, index) => (
              <CarouselItem
                key={index}
                className={`py-[20px] pl-[20px] ${index === TOOLS.length - 1 ? 'pr-[20px]' : ''}`}
              >
                <ToolCard tool={tool} background="white" />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
      <FullWidthContainer>
        <LinkButton
          href="/tools-calculators"
          className="w-full max-w-[400px] small-desktop:max-w-[260px]"
          variant="outline"
          size="lg"
        >
          See all tools
        </LinkButton>
      </FullWidthContainer>
    </section>
  )
}
