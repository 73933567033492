import FullWidthContainer from '@components/layout/full-width-container'
import { USMap } from '@components/us-map/us-map'

type Props = {
  userStateId: string | undefined
}

export const MapSection = ({ userStateId }: Props) => {
  return (
    <section className="mt-[60px] mb-[60px] small-desktop:mt-[80px] small-desktop:mb-0">
      <FullWidthContainer>
        <div className="flex flex-col mb-[10px] small-desktop:mb-[35px]">
          <h2 className="text-title-md small-desktop:text-title-lg-desktop mb-[10px] small-desktop:mb-[15px]">Find a 529 plan in your state</h2>
          <p className="small-desktop:text-body-lead">Select a state on the map to see available plans:</p>
        </div>
        <div className="mx-[-20px] tablet:mx-auto">
          <USMap userStateId={userStateId} breakpoint="small-desktop" />
        </div>
      </FullWidthContainer>
    </section>
  )
}
